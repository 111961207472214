import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotify } from '../context/NotificationContext';
import { useAuth } from '../context/AuthContext';
import '../styles/ClientManagement.css';

const ClientManagement = () => {
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewMode, setViewMode] = useState('table'); // 'card' or 'table'
    const navigate = useNavigate();
    const { error, success, choice } = useNotify();
    const { getCookie, APP_URL } = useAuth();

    useEffect(() => {
        fetch(`${APP_URL}/clients`, {
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    resp.json().then(setClients);
                } else {
                    resp.json().then(error);
                }
            })
            .catch(e => error(e));
    }, []); //eslint-disable-line

    useEffect(() => {
        setFilteredClients(clients);
    }, [clients]);

    const handleSearch = () => {
        const trimmedQuery = searchQuery.trim();
        if (trimmedQuery === '') {
            setFilteredClients(clients);
        } else {
            const filteredClients = clients.filter(client =>
                client.legal_name.toLowerCase().includes(trimmedQuery.toLowerCase()) ||
                client.email.toLowerCase().includes(trimmedQuery.toLowerCase()) ||
                client.client_id.toString().includes(trimmedQuery)
            );
            setFilteredClients(filteredClients);
        }
    };

    const handleEdit = (e, _client) => {
        e.stopPropagation();
        navigate(`/clients/${_client.id}/edit`);
    };

    const handleDelete = (e, _client) => {
        e.stopPropagation();
        choice(`Are you sure you want to delete client:${_client.id}?`, () => handleConfirmDelete(_client));
    };

    const handleConfirmDelete = (_client) => {
        fetch(`${APP_URL}/clients/${_client.id}`, {
            method: "DELETE",
            headers: {
                "X-CSRF-TOKEN": getCookie('csrf_access_token'),
            },
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    setClients(prev => prev.filter(acc => acc.id !== _client.id));
                    success("User deleted!");
                } else {
                    resp.json().then(error);
                }
            }).catch(e => error(e));
    };

    return (
        <div className="container mt-4">
            <h2>Business Clients</h2>
            <div className="mb-3">
                <div className='input-group'>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search by legal name, email, or ID"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button className="btn btn-primary" onClick={handleSearch}>
                        Search
                    </button>
                </div>
                <button className="btn btn-primary" onClick={() => navigate('/clients/create')}>
                    Create Client
                </button>
                <button className="btn btn-secondary m-2" onClick={() => setViewMode(viewMode === 'card' ? 'table' : 'card')}>
                    Switch to {viewMode === 'card' ? 'Table' : 'Card'} View
                </button>
            </div>

            {viewMode === 'card' ? (
                <div className="client-cards">
                    {filteredClients.map(client => (
                        <div key={client.id} className="client-card" onClick={() => navigate(`/clients/${client.id}`)}>
                            <div className="client-card-body">
                                <h5 className="client-card-title">{client.legal_name}</h5>
                                <p className="client-card-text">Client ID: {client.client_id}</p>
                                <p className="client-card-text">EIN: {client.ein}</p>
                                <p className="client-card-text">Time Entries: {client.time_entries.length}</p>
                                <div className="client-card-actions">
                                    <button className="btn btn-warning btn-sm mx-1" onClick={(e) => handleEdit(e, client)}>
                                        Edit
                                    </button>
                                    <button className="btn btn-danger btn-sm mx-1" onClick={(e) => handleDelete(e, client)}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Client ID</th>
                            <th>Legal Name</th>
                            <th>EIN</th>
                            <th>Time Entries</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredClients.map(client => (
                            <tr key={client.id} style={{ cursor: "pointer" }} onClick={() => navigate(`/clients/${client.id}`)}>
                                <td>{client.client_id}</td>
                                <td>{client.legal_name}</td>
                                <td>{client.ein}</td>
                                <td>{client.time_entries.length}</td>
                                <td>
                                    <button className="btn btn-warning btn-sm mx-1" onClick={(e) => handleEdit(e, client)}>
                                        Edit
                                    </button>
                                    <button className="btn btn-danger btn-sm mx-1" onClick={(e) => handleDelete(e, client)}>
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default ClientManagement;
