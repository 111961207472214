import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useNotify } from '../context/NotificationContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logo from '../assets/clockwise.png';

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const popup = searchParams.get('popup') ? true : false;
  const [showLoginForm, setShowLoginForm] = useState(popup); // State to toggle login form visibility
  const [showContactForm, setShowContactForm] = useState(false);
  
  const { error, success } = useNotify();
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleContactClick = () => {
    setShowContactForm(true);
  };

  const handleCloseContactForm = () => {
    setShowContactForm(false);
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().min(5,'Username must be at least 5 characters.').max(15,'Username must be at most 15 characters').required('Username is required'),
      password: Yup.string().min(5,'Password must be at least 5 characters.').max(15,'Password must be at most 15 characters').required('Password is required'),
    }),
    onSubmit: (values) => {
      login(values.username, values.password)
        .then((data) => {
          if (data && !data.error) {
            success(data);
            navigate('/');
          } else {
            error(data);
          }
        })
        .catch((e) => error(e));
    },
  });

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Navigation */}
      <nav className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center py-4">
            <button className="text-gray-700 text-lg font-semibold" onClick={handleContactClick}>Get In Contact</button>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="py-20">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center">
            <h1 className="text-5xl font-bold text-gray-800 mb-4">Clockwise</h1>
            <p className="text-lg text-gray-600 mb-8"><i>When time is your asset</i></p>
            <img src={logo} alt='' className="w-64 h-auto mx-auto mb-8" /> {/* Adjust the width to 64 */}
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
              onClick={() => setShowLoginForm(true)} // Show login form on button click
            >
              Log In
            </button>
          </div>
        </div>
      </section>

      {/* Login Form */}
      {showLoginForm && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="card my-5 mx-auto" style={{ background: 'linear-gradient(135deg, #3498db, #6cb1ed)', borderRadius: '1rem' }}>
            {/* Close Button */}
            <button
              className="absolute top-3 right-3 text-white text-lg focus:outline-none"
              onClick={() => setShowLoginForm(false)} // Close the login form on button click
            >
              <span>&times;</span>
            </button>
            {/* End of Close Button */}
            <div className="card-body p-5 d-flex flex-column align-items-center mx-auto w-200">
              <h2 className="fw-bold mb-2 text-uppercase text-white">Login</h2>
              <p className="text-white mb-5">Please enter your login and password!</p>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-4 w-100">
                  <label className="text-white" htmlFor="username">
                    Username:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.username && formik.errors.username ? (
                    <div className="text-red-500">{formik.errors.username}</div>
                  ) : null}
                </div>
                <div className="mb-4 w-100">
                  <label className="text-white" htmlFor="password">
                    Password:
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-red-500">{formik.errors.password}</div>
                  ) : null}
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-outline-light mx-2 px-5">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Contact Form Popup */}
      {showContactForm && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="card my-5 mx-auto p-5 bg-white">
            <button
              className="absolute top-3 right-3 text-gray-700 text-lg focus:outline-none"
              onClick={handleCloseContactForm}
            >
              <span>&times;</span>
            </button>
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <p className="mb-4">For any queries or assistance, please contact us at:</p>
            <ul>
              <li>Email: example@example.com</li>
              <li>Phone: 123-456-7890</li>
            </ul>
          </div>
        </div>
      )}

      {/* Features Section */}
      <section className="bg-gray-200 py-20">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center">
            <h2 className="text-4xl font-bold text-gray-800 mb-12">Features</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <div className="bg-white p-8 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Time Tracking</h3>
              <p className="text-gray-600">Effortlessly monitor billable hours with intuitive features</p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Invoicing & Progressive Billing</h3>
              <p className="text-gray-600">Create polished invoices with progress billing for faster payments.</p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold text-gray-800 mb-4">Accounts Receivable</h3>
              <p className="text-gray-600">Manage payments efficiently with status updates</p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-8">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <p>&copy; 2024 Clockwise. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default LoginPage;
