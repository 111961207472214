import React, { useState } from 'react';
import '../styles/ReportsPage.css';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useUtils } from '../context/UtilContext';
import { useNavigate } from 'react-router-dom';

const ReportsPage = () => {
  const [selectedReport, setSelectedReport] = useState('');
  const [currentReport, setCurrentReport] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const { APP_URL, getCookie } = useAuth();
  const { formatCurrency } = useUtils();
  const navigate = useNavigate()
  const { error } = useNotify();

  const reportsOptions = [
    'Accounts Receivable Aging',
    'Invoice Journal',
    'Activity Type',
    'Profit and Loss',
  ];

  const handleSendReport = () => {
    setLoading(true);

    const requestBody = { report_name: selectedReport };

    if (!selectedReport){
      error('Please select a report')
      setLoading(false);
      return;
      
    }

    if (selectedReport !== 'Accounts Receivable Aging') {
      if (startDate && endDate) {
        requestBody.start_date = startDate;
        requestBody.end_date = endDate;
      } else {
        error('Start and end date must be selected');
        setLoading(false);
        return;
      }
    }

    fetch(`${APP_URL}/reports`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })
      .then(resp => {
        if (resp.ok) {
          resp.json().then(data => {
            setCurrentReport(data);
            setLoading(false);
          });
        } else {
          error(resp);
          setLoading(false);
        }
      })
      .catch(e => {
        error(e);
        setLoading(false);
      });
  };

  const handleReportChange = (e) => {
    setSelectedReport(e.target.value);
    setCurrentReport(null);
    if (e.target.value === 'Accounts Receivable Aging') {
      setStartDate('');
      setEndDate('');
    }
  };

  console.log(currentReport)


  return (
    <div className="container mx-auto p-4 mt-8 bg-white rounded shadow">
      <h2 className="text-2xl font-bold mb-4">Reports</h2>
      <div className="mb-3">
        <label htmlFor="report-dropdown" className="form-label">Select Report:</label>
        <select
          id="report-dropdown"
          className="form-select w-1/3"
          value={selectedReport}
          onChange={handleReportChange}
        >
          <option value="">Select a Report</option>
          {reportsOptions.map((report) => (
            <option key={report} value={report}>
              {report}
            </option>
          ))}
        </select>
      </div>

      {selectedReport !== 'Accounts Receivable Aging' && selectedReport && (
        <div className="mb-3 flex items-center">
          <div className="mr-4">
            <label htmlFor="start-date" className="form-label">Start Date:</label>
            <input
              type="date"
              id="start-date"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="end-date" className="form-label">End Date:</label>
            <input
              type="date"
              id="end-date"
              className="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
      )}

      <div className="mb-4">
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-md"
          onClick={handleSendReport}
          disabled={loading || !selectedReport}
        >
          {loading ? 'Loading...' : 'Fetch Data'}
        </button>
      </div>

      {loading && (
        <div className="alert alert-info w-100 mt-2 text-center" role="alert">
          <strong>Loading data, please wait...</strong>
        </div>
      )}

      {currentReport && !loading && (
        <div className="report-table-container">
          <h3 className="report-title">{selectedReport}</h3>
          {selectedReport === 'Accounts Receivable Aging' ? (

            <div className="report-section mb-4">
              <table className="min-w-full bg-white border-collapse border border-gray-200">
                {currentReport.map((userReport, userIndex) => {console.log(userReport);return(
                  <>
                    <thead>
                      {userIndex !== 0 &&
                        <tr>
                          <th className="p-2 border border-gray-300"></th>
                          <th className="p-2 border border-gray-300"></th>
                          <th className="p-2 border border-gray-300"></th>
                          <th className="p-2 border border-gray-300"></th>
                          <th className="p-2 border border-gray-300"></th>
                          <th className="p-2 border border-gray-300"></th>
                        </tr>
                      }
                      <tr>
                        {!(userReport[0][0] === 'Grand Total') &&
                          <>
                            <th className="p-2 border border-gray-300">Partner: {userReport[0][0]}</th>
                            <th className="p-2 border border-gray-300">ID: {userReport[0][1]}</th>
                            <th className="p-2 border border-gray-300"></th>
                            <th className="p-2 border border-gray-300"></th>
                            <th className="p-2 border border-gray-300"></th>
                            <th className="p-2 border border-gray-300"></th>
                          </>
                        }
                        {(userReport[0][0] === 'Grand Total') &&
                          <>
                            <th className="p-2 border border-gray-300">Grand Totals</th>
                            <th className="p-2 border border-gray-300"></th>
                            <th className="p-2 border border-gray-300"></th>
                            <th className="p-2 border border-gray-300"></th>
                            <th className="p-2 border border-gray-300"></th>
                            <th className="p-2 border border-gray-300"></th>
                          </>}
                      </tr>
                      <tr>


                        {!(userReport[0][0] === 'Grand Total') && userReport[0].slice(2).map((header, index) => (
                          <th key={index} className="p-2 border border-gray-300">
                            {header}
                          </th>
                        ))}
                        {(userReport[0][0] === 'Grand Total') &&
                          userReport[0].slice(1).map((cell, cellIndex) => (
                            <td key={cellIndex} className={`p-2 border border-gray-300 ${typeof cell === 'number' ? 'text-right' : ''}`}>
                              {typeof cell === 'number' ? formatCurrency(cell) : cell}
                            </td>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {userReport.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex} className="border-b hover:bg-gray-100" style={{cursor: 'pointer'}} onClick={() => navigate(`/contracts/manage?client_id=${row[0]}`)}>
                          {row.slice(1).map((cell, cellIndex) => (
                            <td key={cellIndex} className={`p-2 border border-gray-300 ${typeof cell === 'number' ? 'text-right' : ''}`}>
                              {typeof cell === 'number' ? formatCurrency(cell) : cell}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </>
                )})}
              </table>
            </div>

          )
          :
          selectedReport === 'Invoice Journal' ? (
            <div className="report-section mb-4">
              <table className="min-w-full bg-white border-collapse border border-gray-200">
                {currentReport.map((userReport, userIndex) => {console.log(userReport);return(
                  <>
                    <thead>
                      {userIndex !== 0 &&
                        <tr>
                          <th className="p-2 border border-gray-300"></th>
                          <th className="p-2 border border-gray-300"></th>
                          <th className="p-2 border border-gray-300"></th>
                          <th className="p-2 border border-gray-300"></th>
                          <th className="p-2 border border-gray-300"></th>
                        </tr>
                      }
                      <tr>
                        {!(userReport[0][0] === 'Grand Total') &&
                          <>
                            <th className="p-2 border border-gray-300">Partner: {userReport[0][0]}</th>
                            <th className="p-2 border border-gray-300">ID: {userReport[0][1]}</th>
                            <th className="p-2 border border-gray-300"></th>
                            <th className="p-2 border border-gray-300"></th>
                            <th className="p-2 border border-gray-300"></th>
                          </>
                        }
                        {(userReport[0][0] === 'Grand Total') &&
                          <>
                            <th className="p-2 border border-gray-300">Grand Total</th>
                            <th className="p-2 border border-gray-300"></th>
                            <th className="p-2 border border-gray-300"></th>
                            <th className="p-2 border border-gray-300"></th>
                            <th className="p-2 border border-gray-300"></th>
                          </>}
                      </tr>
                      <tr>


                        {!(userReport[0][0] === 'Grand Total') && userReport[0].slice(2).map((header, index) => (
                          <th key={index} className="p-2 border border-gray-300">
                            {header}
                          </th>
                        ))}
                        {(userReport[0][0] === 'Grand Total') &&
                          userReport[0].slice(1).map((cell, cellIndex) => (
                            <td key={cellIndex} className={`p-2 border border-gray-300 ${typeof cell === 'number' ? 'text-right' : ''}`}>
                              {typeof cell === 'number' ? formatCurrency(cell) : cell}
                            </td>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {userReport.slice(1).map((row, rowIndex) => (
                        <tr key={rowIndex} className="border-b hover:bg-gray-100">
                          {row.map((cell, cellIndex) => (
                            <td key={cellIndex} className={`p-2 border border-gray-300 ${typeof cell === 'number' ? 'text-right' : ''}`}>
                              {typeof cell === 'number' ? formatCurrency(cell) : cell}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </>
                )})}
              </table>
            </div>
          ) : (
            <table className="min-w-full bg-white border-collapse border border-gray-200">
              <thead>
                <tr>
                  {currentReport[0].map((header, index) => (
                    <th key={index} className="p-2 border border-gray-300">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentReport.slice(1, currentReport.length).map((row, rowIndex) => (
                  <tr key={rowIndex} className="border-b hover:bg-gray-100">
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex} className="p-2 border border-gray-300">
                        {typeof cell === 'number' ? formatCurrency(cell) : cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default ReportsPage;
