import React, { useState, useEffect } from 'react';
import { useNotify } from '../context/NotificationContext';
import { useAuth } from '../context/AuthContext';

const ActivityTypesPage = () => {
  const { error, choice } = useNotify();
  const { getCookie, APP_URL } = useAuth();
  const [activityTypes, setActivityTypes] = useState([]);
  const [editableActivityType, setEditableActivityType] = useState(null);
  const [newActivityType, setNewActivityType] = useState('');

  useEffect(() => {
    fetch(`${APP_URL}/activity-types`,{
      credentials: 'include'
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((data) => setActivityTypes(data));
        } else {
          resp.json().then(error);
        }
      })
      .catch((e) => error(e));
  }, [error]); //eslint-disable-line

  const handleEdit = (id, name, value) => {
    setEditableActivityType({ id, name, value });
  };

  const handleUpdate = (id) => {
    fetch(`${APP_URL}/activity-types/${id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
      },
      credentials: 'include',
      body: JSON.stringify({
        name: editableActivityType.name,
        value: parseFloat(editableActivityType.value),
      }),
    })
      .then((resp) => {
        if (resp.ok) {
          setEditableActivityType(null);
          fetch(`${APP_URL}/activity-types`,{
            credentials: 'include'
          })
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((data) => setActivityTypes(data));
              } else {
                resp.json().then(error);
              }
            })
            .catch((e) => error(e));
        } else {
          resp.json().then(error);
        }
      })
      .catch((e) => error(e));
  };

  const handleDeleteClick = (activityType) => {
    choice(`Do you want to delete activity type: ${activityType.name}?`, () => handleDelete(activityType.id));
  };

  const handleDelete = (id) => {
    fetch(`${APP_URL}/activity-types/${id}`, {
      method: 'DELETE',
      headers: {
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
      },
      credentials: 'include'
    })
      .then((resp) => {
        if (resp.ok) {
          setEditableActivityType(null);
          fetch(`${APP_URL}/activity-types`,{
            credentials: 'include'
          })
            .then((response) => {
              if (response.ok) {
                response.json().then(setActivityTypes);
              } else {
                error(response);
              }
            })
            .catch((e) => error(e));
        } else {
          error(resp);
        }
      })
      .catch((e) => error(e));
  };

  const handleAdd = () => {

    fetch(`${APP_URL}/activity-types`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
      },
      body: JSON.stringify({ name: newActivityType}),
      credentials: 'include'
    })
      .then((resp) => {
        if (resp.ok) {
          setNewActivityType('');
          fetch(`${APP_URL}/activity-types`,{credentials:'include'})
            .then((response) => {
              if (response.ok) {
                response.json().then((data) => setActivityTypes(data));
              } else {
                error(response);
              }
            })
            .catch((e) => error(e));
        } else {
          error(resp);
        }
      })
      .catch((e) => error(e));
  };

  return(
    <div className="container mt-4">
      <h2 className="text-2xl font-bold">Activity Types</h2>
      {activityTypes.length === 0 && <p>No activity types available. You can add a new activity type below.</p>}
      <ul className="list-group">
        {activityTypes.map((activityType) => (
          <li key={activityType.id} className="list-group-item d-flex justify-content-between align-items-center">
            {editableActivityType && editableActivityType.id === activityType.id ? (
              <>
                <input
                  type="text"
                  className="form-control me-2"
                  value={editableActivityType.name}
                  onChange={(e) => setEditableActivityType({ ...editableActivityType, name: e.target.value })}
                />
                <button className="btn btn-success" onClick={() => handleUpdate(editableActivityType.id)}>
                  Save
                </button>
              </>
            ) : (
              <>
                {activityType.name} - {activityType.charge_type ? 'Chargeable' : 'Non Chargable'}
                <div>
                  <button className="btn btn-warning me-2" onClick={() => handleEdit(activityType.id, activityType.name, activityType.value)}>
                    Edit
                  </button>
                  <button className="btn btn-danger" onClick={() => handleDeleteClick(activityType)}>
                    Delete
                  </button>
                </div>
              </>
            )}
          </li>
        ))}
        <li className="list-group-item d-flex justify-content-between align-items-center">
        <input
            type="text"
            className="form-control me-2"
            placeholder="Activity Type Name"
            value={newActivityType}
            onChange={(e) => setNewActivityType(e.target.value)}
          />
          <button className="btn btn-primary" onClick={handleAdd}>
            Add
          </button>
        </li>
      </ul>
    </div>
  )
};

export default ActivityTypesPage;