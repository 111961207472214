import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNotify } from '../context/NotificationContext';
import { useUtils } from '../context/UtilContext';

const MyTimeEntries = () => {
  const { user, APP_URL } = useAuth();
  const [timeEntries, setTimeEntries] = useState([]);
  const [sortedTimeEntries, setSortedTimeEntries] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sheetDate, setSheetDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [useSheetDate, setUseSheetDate] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ id: user?.id, username: user?.username, role: user?.role });
  const { error } = useNotify();
  const { formatDate, formatCurrency, sum } = useUtils();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && (user.role === 'Partner' || user?.role === 'Staff Mgr')) {
      fetch(`${APP_URL}/users`, {
        credentials: 'include'
      })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(data => setUsers(data));
          } else {
            error(resp);
          }
        })
        .catch((e) => error(e));
    }
  }, [APP_URL, error, user]);

  useEffect(() => {
    if (user && selectedUser.username) {
      fetch(`${APP_URL}/users/${selectedUser.id}`, {
        credentials: 'include'
      })
        .then(resp => {
          if (resp.ok) {
            resp.json().then(data => {
              const time_entries = data.created_time_entries;

              let filteredTimeEntries = time_entries;

              if (useSheetDate && sheetDate) {
                const { sheetStart, sheetEnd } = getSheetDateRange(sheetDate);
                filteredTimeEntries = time_entries.filter(timeEntry => {
                  const timeEntryDate = new Date(timeEntry.start_date);
                  return timeEntryDate >= sheetStart && timeEntryDate <= sheetEnd;
                });
              } else if (startDate && endDate) {
                filteredTimeEntries = time_entries.filter(timeEntry => {
                  const timeEntryDate = new Date(timeEntry.start_date);
                  return timeEntryDate >= startDate && timeEntryDate <= endDate;
                });
              }

              setSortedTimeEntries(filteredTimeEntries.sort((a, b) => new Date(a.start_date) - new Date(b.start_date)));
              setTimeEntries(time_entries);
              setLoading(false);
            })
              .catch(e => {
                error(e);
                setLoading(false);
              });
          } else {
            navigate('/error');
            setLoading(false);
          }
        });
    }
  }, [selectedUser, navigate, APP_URL, error, sheetDate, startDate, endDate, useSheetDate]);

  useEffect(() => {
    const getCurrentFriday = () => {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const distanceToFriday = (5 - dayOfWeek + 7) % 7;
      const friday = new Date(today);
      friday.setDate(today.getDate() + distanceToFriday);
      friday.setHours(0, 0, 0, 0);
      return friday;
    };

    setSheetDate(getCurrentFriday());
  }, []);

  const getSheetDateRange = (selectedDate) => {
    const sheetEnd = new Date(selectedDate);
    sheetEnd.setHours(23, 59, 59, 999);

    // Find previous Saturday
    const sheetStart = new Date(sheetEnd);
    sheetStart.setDate(sheetEnd.getDate() - (sheetEnd.getDay() + 1) % 7);

    return { sheetStart, sheetEnd };
  };

  const handleSort = (sortBy) => {
    const sorted = [...sortedTimeEntries].sort((a, b) => {
      if (sortBy === 'activity') {
        return a.activity_type.name.localeCompare(b.activity_type.name);
      }
      return new Date(a[sortBy]) - new Date(b[sortBy]);
    });
    setSortedTimeEntries(sorted);
  };

  const handleSearch = () => {
    let filtered = timeEntries;

    if (useSheetDate && sheetDate) {
      const { sheetStart, sheetEnd } = getSheetDateRange(sheetDate);
      filtered = filtered.filter(timeEntry => {
        const timeEntryDate = new Date(timeEntry.start_date);
        return timeEntryDate >= sheetStart && timeEntryDate <= sheetEnd;
      });
    } else if (startDate && endDate) {
      filtered = filtered.filter(timeEntry => {
        const timeEntryDate = new Date(timeEntry.start_date);
        return timeEntryDate >= startDate && timeEntryDate <= endDate;
      });
    }

    filtered = filtered.filter((timeEntry) => {
      return (
        timeEntry.client.client_id.toString().includes(searchQuery.toLowerCase()) ||
        timeEntry.client.legal_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        timeEntry.activity_type.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });

    setSortedTimeEntries(filtered);
  };

  const handleToggleView = () => {
    setUseSheetDate(!useSheetDate);
  };

  const handleClearFilters = () => {
    setSearchQuery('');
    setStartDate(null);
    setEndDate(null);
    setSheetDate(null);
    setSortedTimeEntries(timeEntries);
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day === 5;
  };

  // Calculate total hours
  const totalHours = sum(sortedTimeEntries, 'hours', 0);

  return (
    <div className="container mt-4">
      <div className="mb-3">
        <h1 className='text-xl'>Viewing Time Entries for <b>{selectedUser.username}</b></h1>
      </div>
      <div className="mb-3">
        <button className="btn btn-secondary btn-sm" onClick={() => handleSort('client_id')}>
          Sort by Client Id
        </button>
        <button className="btn btn-secondary btn-sm mx-2" onClick={() => handleSort('activity')}>
          Sort by Activity
        </button>
        <button className="btn btn-secondary btn-sm" onClick={() => handleSort('start_date')}>
          Sort by Date
        </button>
        <button className="btn btn-primary btn-sm ml-2" onClick={() => navigate('/time_entries/create')}>
          Enter Time
        </button>
        <button className="btn btn-info btn-sm ml-2" onClick={handleToggleView}>
          {useSheetDate ? 'Switch to Date Range' : 'Switch to Sheet Date'}
        </button>
        <button className="btn btn-warning btn-sm ml-2" onClick={handleClearFilters}>
          Clear Filters
        </button>
      </div>
      <div className="mb-3">
        <div className="d-flex">
          <div className="input-group">
            {!useSheetDate && (
              <>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                  className="form-control"
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="End Date"
                  className="form-control ml-2"
                />
              </>
            )}
            {useSheetDate && (
              <DatePicker
                selected={sheetDate}
                onChange={(date) => setSheetDate(date)}
                filterDate={isWeekday}
                placeholderText="Select a Friday"
                className="form-control ml-2"
              />
            )}

            {user?.role === ('Partner' || 'Staff Mgr') &&
              <select className="form-control" id='userSelect' value={selectedUser.id} onChange={(e) => {
                const select = document.querySelector("#userSelect");
                const username = select.options[select.options.selectedIndex].getAttribute('data-username');
                setSelectedUser({ id: e.target.value, username })
              }}>
                <option value="" disabled>Select Staff</option>
                {users?.map((u) => (
                  <option key={u.id} value={u.id} data-username={u.username}>{`${u.username} - ${u.role}`}</option>
                ))}
              </select>}
          </div>

          <input
            type="text"
            placeholder="Search by Client or Activity"
            className="form-control ml-2"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button className="btn btn-secondary btn-sm ml-2" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Client Id</th>
              <th>Client Name</th>
              <th>Activity</th>
              <th>Start Date</th>
              <th>Price</th>
              <th>Total Hours</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="8" className="text-center">Loading...</td>
              </tr>
            ) : sortedTimeEntries.length > 0 ? (
              sortedTimeEntries.map((timeEntry, index) => (
                <tr className='time-entry-hover' key={index}>
                  <td>{timeEntry.client.client_id}</td>
                  <td>{timeEntry.client.legal_name}</td>
                  <td>{timeEntry.activity_type?.name}</td>
                  <td>{formatDate(timeEntry.start_date)}</td>
                  <td>{formatCurrency(timeEntry.total)}</td>
                  <td>{timeEntry.hours.toFixed(2)}</td>
                  <td><button className="btn btn-warning btn-sm mx-1" onClick={()=>navigate(`/time_entries/${timeEntry.id}/edit`)}>
                                        Edit
                                    </button></td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center">No Time Entries found.</td>
              </tr>
            )}

            {/* Total Hours Row */}
            <tr>
              <td colSpan="5" className="text-right"><strong>Total Hours:</strong></td>
              <td><strong>{totalHours.toFixed(2)}</strong></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyTimeEntries;
