import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { Link } from 'react-router-dom';
import { Card, Container, Row, Col, Button, Table, ListGroup } from 'react-bootstrap';
import '../styles/Dashboard.css'; // Import custom CSS for additional styling
import { useUtils } from '../context/UtilContext';

const Dashboard = () => {
    const { user, APP_URL } = useAuth();
    const { formatDate } = useUtils();
    const { error } = useNotify();
    const [activities, setActivities] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [partners, setPartners] = useState([]);
    const [currentDateTime, setCurrentDateTime] = useState('');

    useEffect(() => {
        fetch(`${APP_URL}/activities`, {
            credentials: 'include'
        })
            .then((resp) => {
                if (resp.ok) {
                    resp.json().then(setActivities);
                } else {
                    console.error('Error fetching activities');
                }
            })
            .catch((e) => error(e));

        fetch(`${APP_URL}/invoices`, {
            credentials: 'include'
        })
            .then((resp) => {
                if (resp.ok) {
                    resp.json().then(setInvoices);
                } else {
                    console.error('Error fetching invoices');
                }
            })
            .catch((e) => error(e));

        fetch(`${APP_URL}/users/${user?.id}`, {
            credentials: 'include'
        })
            .then((resp) => {
                if (resp.ok) {
                    resp.json().then(data => setPartners(data.partnered_clients));
                } else {
                    error(resp);
                }
            })
            .catch((e) => error(e));

        // Update the current date and time every second
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date().toLocaleString());
        }, 1000);

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, []); //eslint-disable-line

    return user ? (
        <Container className="mt-5">
            <p className="mb-4 text-align-left text-2xl"><strong>Logged in as {user.username}</strong></p>
            <Row className="g-4">
                {/* Billing Tile */}
                <Col md={6} lg={4}>
                    <Card className="h-100 shadow-sm">
                        <Card.Header className="bg-light text-center">Billing</Card.Header>
                        <Card.Body className="text-center">
                            <Card.Text>View Client Totals</Card.Text>
                            <Button variant="primary" as={Link} to="/billing">Go to Billing</Button>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Payments Tile */}
                <Col md={6} lg={4}>
                    <Card className="h-100 shadow-sm">
                        <Card.Header className="bg-light text-center">Payments</Card.Header>
                        <Card.Body className="text-center">
                            <Card.Text>Record Client Payments</Card.Text>
                            <Button variant="primary" as={Link} to="/clients/manage">Record Payments</Button>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Time and Expense Tile */}
                <Col md={6} lg={4}>
                    <Card className="h-100 shadow-sm">
                        <Card.Header className="bg-light text-center">Time and Expense</Card.Header>
                        <Card.Body className="text-center">
                            <Card.Text>Record Your Time</Card.Text>
                            <Button variant="primary" as={Link} to="/profile">Record Time</Button>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Client Operations Tile */}
                <Col md={6} lg={4}>
                    <Card className="h-100 shadow-sm">
                        <Card.Header className="bg-light text-center">Client Operations</Card.Header>
                        <Card.Body className="text-center">
                            <Button variant="primary" as={Link} to="/clients/manage">Manage Clients</Button>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Reports Operations Tile */}
                <Col md={6} lg={4}>
                    <Card className="h-100 shadow-sm">
                        <Card.Header className="bg-light text-center">Report Operations</Card.Header>
                        <Card.Body className="text-center">
                            <Button variant="primary" as={Link} to="/reports">Reports</Button>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Partners Tile */}
                <Col md={6} lg={4}>
                    <Card className="h-100 shadow-sm">
                        <Card.Header className="bg-light text-center">Partners</Card.Header>
                        <Card.Body>
                            {partners.length ? (
                                <ListGroup variant="flush">
                                    {partners.map((partner) => (
                                        <ListGroup.Item key={partner.id}>{partner.legal_name} - {partner.client_id}</ListGroup.Item>
                                    ))}
                                </ListGroup>
                            ) : (
                                <p className="text-muted text-center">No partnered accounts found.</p>
                            )}
                        </Card.Body>
                    </Card>
                </Col>

                {/* Latest Invoices Tile */}
                <Col md={6} lg={4}>
                    <Card className="h-100 shadow-sm">
                        <Card.Header className="bg-light text-center">Latest Invoices</Card.Header>
                        <Card.Body>
                            {invoices.length ? (
                                <Table striped hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Invoice ID</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoices.slice(0, 5).map((invoice) => (
                                            <tr key={invoice.id}>
                                                <td>{invoice.id}</td>
                                                <td>{formatDate(invoice.created_at)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p className="text-muted text-center">No recent invoices found.</p>
                            )}
                        </Card.Body>
                    </Card>
                </Col>

                {/* Current Date and Time Tile */}
                <Col md={6} lg={4}>
                    <Card className="h-100 shadow-sm">
                        <Card.Header className="bg-light text-center">Current Date and Time</Card.Header>
                        <Card.Body className="text-center">
                            <p className="text-muted">{currentDateTime}</p>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Recent Activity Tile */}
                <Col md={6} lg={4}>
                    <Card className="h-100 shadow-sm">
                        <Card.Header className="bg-light text-center">Recent Activity</Card.Header>
                        <Card.Body>
                            {activities.length ? (
                                <ListGroup variant="flush">
                                    {activities.slice(0, 5).map((activity) => (
                                        <ListGroup.Item key={activity.id}>{activity.action}</ListGroup.Item>
                                    ))}
                                </ListGroup>
                            ) : (
                                <p className="text-muted text-center">No recent activities found.</p>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    ) : null;
};

export default Dashboard;