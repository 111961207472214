import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import '../styles/NavBar.css'


const NavBar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { logout, user } = useAuth();
  const { success, error } = useNotify();
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = () => {
    logout().then(data => {
      if (data && !(data.hasOwnProperty('error'))) {
        success('Logged out!');
        navigate('/login');
      } else {
        error(data);
      }
    });
  };

  return (
    <nav className="bg-gray-800 p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white font-bold text-xl">Clockwise</div>

        {/* Desktop menu */}
        <div className="hidden md:flex space-x-4 items-center">
          <NavLink to="/" className="navlink">
            Home
          </NavLink>
          <NavLink to="/profile" className="navlink">
            T&E
          </NavLink>
          {user?.role === 'Partner' &&
            <NavLink to="/admin" className="navlink">
              Admin
            </NavLink>
          }
          <NavLink to="/settings" onClick={() => navigate('/settings')} className="text-white">
              Settings
            </NavLink>
          <NavLink to="/login" onClick={handleLogout} className="navlink">
            Logout
          </NavLink>
          
        </div>

        {/* Mobile menu button */}
        <div className="md:hidden">
          <button className="text-white" onClick={toggleMobileMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden">
          <div className="flex flex-col space-y-2 mt-2">
            <NavLink to="/" className="text-white">
              Home
            </NavLink>
            <NavLink to="/profile" className="text-white">
            T&E
            </NavLink>
            {user?.role === 'Partner' &&
              <NavLink to="/admin" className="text-white">
                Admin
              </NavLink>
            }
            <NavLink to="/settings" onClick={() => navigate('/settings')} className="text-white">
              Settings
            </NavLink>
            <NavLink to="/settings" onClick={handleLogout} className="text-white">
              Logout
            </NavLink>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
